import React, { useEffect, useState } from "react";
import { Badge, Button, Modal } from "react-bootstrap";
import Spinner from "../common/Spinner";
import { notifyError, notifyTopRight } from "../common/Toaster";
import { editBallon } from "../../services/Balloon/BalloonService";
import closeIcon from "../../images/closeIcon.png";
import addPhoto from "../../services/AuthService";
export default function EditBalloon({ show, balloonData, onHide, table }) {
  console.log(balloonData, "old data");

  const [loader, setLoader] = useState(false);
  const [countryCode, setCountryCode] = useState("");
  const [color, setColor] = useState("");
  const [colorFamily, setColorFamily] = useState("");
  const [size, setSize] = useState(balloonData?.size);
  const [newSize, setNewSize] = useState("");
  const [image, setImage] = useState("");
  // const [newImage, setNewImage] = useState("");
  let errorsObj = {
    color: "",
    colorFamily: "",
    size: "",
  };
  const [errors, setErrors] = useState(errorsObj);
  let albumName = "baalls";
  const handleInputChange = (e) => {
    const selectedFile = e.target.files[0];
    if (selectedFile) {
      addPhoto(selectedFile, albumName)
        .then((response) => {
          console.log(response.Location, "image loaction");
          setImage(response.Location);
        })
        .catch((error) => {
          console.log(error, "image upload error");
        });
    }
  };

  const onUpdated = async (e) => {
    e.preventDefault();

    let error = false;
    const errorObj = { ...errorsObj };
    if (color === "") {
      errorObj.color = "This Field is Required !";
      error = true;
    }

    if (colorFamily === "") {
      errorObj.colorFamily = "This Field is Required !";
      error = true;
    }
    // if (size === "") {
    //   errorObj.size = "This Field is Required !";
    //   error = true;
    // }
    setErrors(errorObj);
    if (error) {
      return;
    }
    // setLoader(true);

    try {
      const response = await editBallon(
        balloonData?._id,
        color,
        colorFamily,
        size,
        image
      );
      notifyTopRight("Updated Successfully.");
      table();
      onHide();
      //   setLoader(false);
      console.log(response);
    } catch (error) {
      console.log(error.response, "helooooooooo");
      setLoader(false);
      notifyError(error.response?.data?.message);
    }
  };
  const handleTagsDelete = async (e, index, item) => {
    e.preventDefault();
    const newArr = size.filter((i, indx) => {
      return indx != index;
    });
    setSize(newArr);
  };

  const handleAddSizes = (e) => {
    e.preventDefault();
    const newArr = size;
    if (newSize && newSize != "") {
      newArr.push(newSize.trim());
    }
    setSize(newArr);
    setNewSize("");
  };

  useEffect(() => {
    setColor(balloonData?.color);
    setColorFamily(balloonData?.colorFamily);
    setImage(balloonData?.imageName);
  }, [size]);
  return (
    <Modal className="modal fade" show={show} centered>
      <div className="">
        <form onSubmit={onUpdated}>
          <div className="modal-header">
            <h4 className="modal-title fs-20">Edit Ballon</h4>
            <button
              type="button"
              className="btn close"
              onClick={() => onHide()}
            >
              <span>×</span>
            </button>
          </div>
          <div className="modal-body">
            <i className="flaticon-cancel-12 close"></i>
            <div className="add-contact-box">
              <div className="add-contact-content">
                <div className="form-group mb-3">
                  <label className="text-black font-w500">Image</label>
                  <div className="contact-name d-flex align-items-center">
                    <img src={image} alt="img" width={60} height={60} />
                    <input
                      type="file"
                      accept="image/jpeg,image/png,image/jpg"
                      className="form-control"
                      onChange={handleInputChange}
                    />

                    {errors.color && (
                      <div className="text-danger fs-12">{errors.color}</div>
                    )}
                  </div>
                </div>
                <div className="form-group mb-3">
                  <label className="text-black font-w500">Color</label>
                  <div className="contact-name">
                    <input
                      type="text"
                      className="form-control"
                      value={color}
                      onChange={(e) => setColor(e.target.value)}
                    />

                    {errors.color && (
                      <div className="text-danger fs-12">{errors.color}</div>
                    )}
                  </div>
                </div>
                <div className="form-group mb-3">
                  <label className="text-black font-w500">Color Family</label>
                  <div className="contact-name">
                    <input
                      type="text"
                      className="form-control"
                      value={colorFamily}
                      onChange={(e) => setColorFamily(e.target.value)}
                    />

                    {errors.colorFamily && (
                      <div className="text-danger fs-12">
                        {errors.colorFamily}
                      </div>
                    )}
                  </div>
                </div>
                <div className="form-group mb-3">
                  <label className="text-black font-w500">Size</label>
                  {size.length > 0 && (
                    <div className="mb-2">
                      {size &&
                        size.map((item, index) => {
                          return (
                            <Badge
                              className="tag-name mr-2 mb-2"
                              variant="outline-secondary"
                            >
                              <span className="tag-name-content fs-14">
                                {item}
                              </span>
                              <span className="pl-2">
                                <img
                                  width={20}
                                  className=""
                                  src={closeIcon}
                                  alt="tag-icon"
                                  style={{ cursor: "pointer" }}
                                  onClick={(e) =>
                                    handleTagsDelete(e, index, item)
                                  }
                                />
                              </span>
                            </Badge>
                          );
                        })}
                    </div>
                  )}

                  <div className="contact-name d-flex">
                    <input
                      type="number"
                      className="form-control mr-2"
                      value={newSize}
                      onChange={(e) => setNewSize(e.target.value)}
                    />
                    <button
                      type="button"
                      className="btn btn-primary py-1 px-3"
                      onClick={handleAddSizes}
                    >
                      Add
                    </button>
                    {errors.size && (
                      <div className="text-danger fs-12">{errors.size}</div>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="modal-footer">
            <button type="submit" className="btn btn-info">
              Update
            </button>
            <button
              type="button"
              onClick={() => onHide()}
              className="btn btn-danger"
            >
              {" "}
              <i className="flaticon-delete-1"></i> Discard
            </button>
          </div>
        </form>
      </div>
      {loader && <Spinner />}
    </Modal>
  );
}
