/// Menu
import MetisMenu from "metismenujs";
import React, { Component } from "react";
/// Scroll
import PerfectScrollbar from "react-perfect-scrollbar";
/// Link
import { Link } from "react-router-dom";

import icon1 from "../../../images/icon1.png";
import faqIcon from "../../../images/faq 1.svg";
import faqBlue from "../../../images/faq 1 (1).svg";
class MM extends Component {
  componentDidMount() {
    this.$el = this.el;
    this.mm = new MetisMenu(this.$el);
  }
  componentWillUnmount() {}
  render() {
    return (
      <div className="mm-wrapper">
        <ul className="metismenu" ref={(el) => (this.el = el)}>
          {this.props.children}
        </ul>
      </div>
    );
  }
}

class SideBar extends Component {
  /// Open menu
  componentDidMount() {
    // sidebar open/close
    var btn = document.querySelector(".nav-control");
    var aaa = document.querySelector("#main-wrapper");
    function toggleFunc() {
      return aaa.classList.toggle("menu-toggle");
    }
    btn.addEventListener("click", toggleFunc);
  }
  state = {
    loveEmoji: false,
  };
  render() {
    /// Path
    let path = window.location.pathname;
    path = path.split("/");
    path = path[path.length - 1];

    /// Active menu
    let dashBoard = [
        "",
        "analytics",
        "events",
        "order-list",
        "general-customers",
        "reviews",
        "task",
      ],
      users = ["user-management"],
      balloon = ["balloon-management"],
      faq = ["faqs-management"],
      vendors = ["vendor-management"];

    return (
      <div className="deznav">
        <PerfectScrollbar className="deznav-scroll">
          <MM className="metismenu" id="menu">
            <li className={`${dashBoard.includes(path) ? "mm-active" : ""}`}>
              <Link className=" ai-icon" to="/">
                <i className="flaticon-381-networking"></i>
                <span className="nav-text ">Dashboard</span>
              </Link>
            </li>
            <li className={`${users.includes(path) ? "mm-active" : ""}`}>
              <Link to="user-management" className="ai-icon">
                <i className="flaticon-381-user-9"></i>
                <span className="nav-text ">Customer Management</span>
              </Link>
            </li>
            <li className={`${vendors.includes(path) ? "mm-active" : ""}`}>
              <Link to="vendor-management" className="ai-icon">
                <i className="flaticon-381-user-9"></i>
                <span className="nav-text ">Vendor Management</span>
              </Link>
            </li>
            <li className={`${balloon.includes(path) ? "mm-active" : ""}`}>
              <Link to="balloon-management" className="ai-icon">
                <i className="flaticon-381-controls-8"></i>
                <span className="nav-text ">Balloon Management</span>
              </Link>
            </li>
            <li className={`${faq.includes(path) ? "mm-active" : ""}`}>
              <Link to="faqs-management" className="ai-icon">
                {/* <i className="flaticon-381-upload-1"></i> */}
                <img
                  src={faq.includes(path) ? faqBlue : faqIcon}
                  width={34}
                  className="mr-3"
                />
                <span className="nav-text ">FAQs Management</span>
              </Link>
            </li>
          </MM>
        </PerfectScrollbar>
      </div>
    );
  }
}

export default SideBar;
