import axiosInstance from "../AxiosInstance";
import {
  APPROVE_USER_DETAILS,
  BLOCK_USERS,
  DELETE_USERS,
  GET_USERS,
} from "./UserApiEndPoints";
export function getAllUsers(currentPage, limit, search, sort, filter) {
  return axiosInstance.get(
    GET_USERS +
      `?page=${currentPage}&limit=${limit}&search=${search}&sort=${sort}&isBlocked=${filter}`
  );
}

export function deleteUser(id) {
  return axiosInstance.delete(DELETE_USERS + `?id=${id}`);
}

export function blockUser(id, isBlocked) {
  const postData = { userId: id, isBlocked };
  return axiosInstance.put(BLOCK_USERS, postData);
}

export function approveUser(userId) {
  const postData = { userId };
  return axiosInstance.post(APPROVE_USER_DETAILS, postData);
}
