import React, { useEffect, useState } from "react";
import { Dropdown, Card, Table } from "react-bootstrap";
import PageTitle from "../layouts/PageTitle";
import Spinner from "../common/Spinner";
import Pagination from "../common/Pagination";
import { notifyError, notifyTopRight } from "../common/Toaster";
import { useHistory } from "react-router-dom";
import {
  deleteBallon,
  getBallons,
} from "../../services/Balloon/BalloonService";
import emptyImg from "../../images/Profile_avatar_placeholder_large.png";
import EditBalloon from "../modal/EditBalloon";
import UploadFile from "../modal/UploadFile";
import { connect } from "react-redux";
import { getFiltersData } from "../../services/AuthService";
import { MultiSelect } from "../components/MultiSelect";
function BalloonManagement(props) {
  const isTableCall = props.tableCall;
  const history = useHistory();
  const newpage = props.location.state?.page;
  const prevSearch = props.location.state?.search;
  const [loader, setLoader] = useState(false);
  const [balloons, setBalloons] = useState([]);
  const [pageCount, setPageCount] = useState(0);
  const [currentPage, setCurrentPage] = useState(newpage ? newpage : 0);
  const [search, setSearch] = useState("");
  const [listLength, setListlenght] = useState("");
  const [limit, setLimit] = useState(10);
  const [showEditModal, setShowEditModal] = useState(false);
  const [showAddModal, setShowAddModal] = useState(false);
  const [balloonData, setBalloonData] = useState({});
  const [sort, setSort] = useState("");
  const [filter, setFilter] = useState("");
  const [selectedSize, setSelectedSize] = useState([]);
  const [size, setSize] = useState([]);
  const [texture, setTexture] = useState([]);
  const imgUrl="https://baalls3.s3.us-west-2.amazonaws.com/BalloonImages/"
  const svg1 = (
    <svg width="20px" height="20px" viewBox="0 0 24 24" version="1.1">
      <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
        <rect x="0" y="0" width="24" height="24"></rect>
        <circle fill="#000000" cx="5" cy="12" r="2"></circle>
        <circle fill="#000000" cx="12" cy="12" r="2"></circle>
        <circle fill="#000000" cx="19" cy="12" r="2"></circle>
      </g>
    </svg>
  );
  const options = [
    ...size?.map((item, i) => {
      return { value: item, label: item };
    }),
  ];
  const totalSizes = [...selectedSize];
  const sizes = totalSizes?.map((item, i) => {
    console.log(item?.value, "id...............");
    return item?.value;
  });
  console.log(sizes, "all sizes")

  ///////////////// function to get all users /////////////////////////////////
  const getTableData = async () => {
    setLoader(true);
    try {
      const response = await getBallons(
        currentPage,
        limit,
        search,
        sort,
        filter,
        sizes
      );
      console.log(response.data.data);
      setBalloons(response.data.data.balloons);
      setLoader(false);
      const total = response.data.data.balloonCount;
      setPageCount(Math.ceil(total / limit));
      setListlenght(response.data.data.balloonCount);
    } catch (error) {
      console.log(error, "error");
      setLoader(false);
    }
  };

  const FetchFilterData = async () => {
    setLoader(true);
    try {
      const response = await getFiltersData();
      setSize(response.data.data?.sortedSizes);
      setTexture(response.data.data?.textures);
      console.log(response.data.data, "filter data");
    } catch (error) {
      console.log(error, "error");
      setLoader(false);
    }
  };

  ///////////////// function to delete user /////////////////////////////////
  const onDelete = async (id) => {
    setLoader(true);
    try {
      const response = await deleteBallon(id);
      notifyTopRight("Deleted Successfully.");
      getTableData();
      setLoader(false);
      console.log(response);
    } catch (error) {
      console.log(error.response, "helooooooooo");
      setLoader(false);
      notifyError(error.response?.data?.message);
    }
  };
  const onEdit = (data) => {
    setBalloonData(data);
    setShowEditModal(true);
  };
  useEffect(() => {
    getTableData();
    FetchFilterData();
  }, [currentPage, limit]);
  useEffect(() => {
    if (isTableCall === true) {
      getTableData();
    }
  }, [isTableCall]);
  useEffect(() => {
    if (currentPage) {
      history.push({
        pathname: "/balloon-management",
        state: {
          page: currentPage,
        },
      });
    }
  }, [currentPage]);

  useEffect(() => {
    if (search) {
      history.push({
        pathname: "/balloon-management",
        state: {
          page: currentPage,
          search: search,
        },
      });
    }
  }, [limit, search]);
  return (
    <div>
      <div className="d-flex justify-content-between align-items-center">
        <PageTitle activeMenu="Balloon List" motherMenu="Balloon" />
        <button
          type="button"
          className="btn btn-primary py-2"
          onClick={() => setShowAddModal(true)}
        >
          Add +
        </button>
      </div>

      <Card>
        <Card.Header>
          <div className="col-3">
            <div>
              <div
                className="input-group border bg-white input-group-sm rounded-border"
                style={{ borderRadius: "8px" }}
              >
                <input
                  type="text"
                  name="table_search"
                  className="form-control float-right border-0 search-input"
                  placeholder="Search"
                  value={search}
                  onKeyDown={(e) => {
                    if (e.key === "Enter") {
                      setCurrentPage(0);
                      getTableData();
                    }
                  }}
                  onChange={(e) => setSearch(e.target.value)}
                />
                <div className="input-group-append">
                  <button
                    type="button"
                    className="btn btn-default"
                    onClick={getTableData}
                  >
                    <i className="fa fa-search" />
                  </button>
                </div>
              </div>
            </div>
          </div>
          <div
            className="col-4 d-flex justify-content-between align-items-center"
            style={{ gap: "1rem" }}
          >
            <select
              className="form-control"
              onChange={(e) => setSort(e.target.value)}
            >
              <option value={""}>Sort By</option>
              <option value={"alphabetical"}>Alphabetically</option>
              <option value={"latest"}>Latest</option>
            </select>
            <select
              className="form-control"
              onChange={(e) => setFilter(e.target.value)}
            >
              <option value={""}>Filter By Texture</option>
              {texture?.map((item) => (
                <option>{item}</option>
              ))}
            </select>
            {/* <select
              className="form-control"
              value={selectedSize}
              onChange={(e) => setSelectedSize(e.target.value)}
            >
              <option value={""}>Filter By Size</option>
              {size?.map((item) => (
                <option>{item}</option>
              ))}
            </select> */}
          </div>
          <div className="col-3">
            <MultiSelect
              className="form-control"
              options={options}
              value={selectedSize}
              onChange={setSelectedSize}
              required
            />
          </div>
          <button
            type="button"
            className="btn btn-primary py-2"
            onClick={getTableData}
          >
            Search
          </button>
        </Card.Header>
        <Card.Body>
          {loader ? (
            <Spinner />
          ) : (
            <Table responsive>
              <thead className="text-black">
                <tr>
                  <th>
                    <strong>Image</strong>
                  </th>
                  <th>
                    <strong>Brand</strong>
                  </th>

                  <th>
                    <strong>color</strong>
                  </th>
                  <th>
                    <strong>color Family</strong>
                  </th>
                  <th>
                    <strong>color Texture</strong>
                  </th>
                  <th>
                    <strong>Sizes</strong>
                  </th>
                  <th>
                    <strong>ACTION</strong>
                  </th>
                </tr>
              </thead>
              <tbody>
                {balloons?.map((item, i) => (
                  <tr>
                    <td>
                      <img
                        src={item?.imageName ? imgUrl+item?.imageName : emptyImg}
                        width={70}
                        height={70}
                      />
                    </td>
                    <td>{item?.brand}</td>
                    <td>{item?.color}</td>
                    <td>{item?.colorFamily ? item?.colorFamily : "--"}</td>
                    <td>{item?.colorTexture ? item?.colorTexture : "--"}</td>

                    <td>{item?.size.join(", ")}</td>
                    <td>
                      <Dropdown>
                        <Dropdown.Toggle
                          variant="primary light"
                          className="light sharp btn btn-primary i-false"
                        >
                          {svg1}
                        </Dropdown.Toggle>
                        <Dropdown.Menu>
                          <Dropdown.Item onClick={() => onEdit(item)}>
                            Edit
                          </Dropdown.Item>

                          <Dropdown.Item onClick={() => onDelete(item?._id)}>
                            Delete
                          </Dropdown.Item>
                        </Dropdown.Menu>
                      </Dropdown>
                    </td>
                  </tr>
                ))}
              </tbody>
            </Table>
          )}
          {balloons?.length === 0 && !loader && (
            <div className="justify-content-center d-flex my-5 ">
              Sorry, Data Not Found!
            </div>
          )}

          {balloons?.length !== 0 && (
            <div className="card-footer clearfix">
              <div className="d-flex justify-content-between align-items-center">
                <div className="dataTables_info">
                  Showing {currentPage * limit + 1} to{" "}
                  {listLength > (currentPage + 1) * limit
                    ? (currentPage + 1) * limit
                    : listLength}{" "}
                  of {listLength} entries
                </div>
                <Pagination
                  pageCount={pageCount}
                  pageValue={currentPage}
                  setPage={setCurrentPage}
                />
              </div>
              <div>
                <label className="mr-2">Result Per page : </label>
                <select
                  className=""
                  value={limit}
                  onChange={(e) => setLimit(e.target.value)}
                >
                  <option>10</option>
                  <option>20</option>
                  <option>30</option>
                  <option>50</option>
                  <option>100</option>
                </select>
              </div>
            </div>
          )}
        </Card.Body>
      </Card>
      {showEditModal && (
        <EditBalloon
          show={showEditModal}
          balloonData={balloonData}
          table={getTableData}
          onHide={() => setShowEditModal(false)}
        />
      )}
      {showAddModal && (
        <UploadFile show={showAddModal} onHide={() => setShowAddModal(false)} />
      )}
    </div>
  );
}
const mapStateToProps = (state) => {
  return {
    auth: state.auth.auth,
    tableCall: state.auth.tableCall,
  };
};
export default connect(mapStateToProps)(BalloonManagement);
