import axiosInstance from "../AxiosInstance";
import { ADD_FAQS, DELETE_FAQS, EDIT_FAQS, FAQS } from "./FaqsEndPoints";

///get faqs list data//
export async function getFaqsList(page, limit) {
  const response = await axiosInstance.get(
    FAQS + `?page=${page}&limit=${limit}`
  );
  return response;
}
export async function postFaq(formData) {
  const response = await axiosInstance.post(ADD_FAQS, formData);
  return response;
}
export async function deleteFaq(id) {
  const response = await axiosInstance.delete(DELETE_FAQS + `?faqId=${id}`);
  return response;
}
export async function editFaq(formData) {
  const response = await axiosInstance.put(EDIT_FAQS, formData);
  return response;
}
