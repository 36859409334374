import axiosInstance from "../AxiosInstance";
import {
  DELETE_BALLOON,
  EDIT_BALLOON,
  GET_BALLOON,
} from "./BalloonApiEndpoints";

export function getBallons(
  currentPage,
  limit,
  search,
  sort,
  colorTexture,
  size
) {
  const params = {
    page: currentPage,
    limit: limit,
    search: search,
    sort: sort,
    colorTexture: colorTexture,
    size,
  };

  return axiosInstance.get(GET_BALLOON, { params });
}

export function deleteBallon(id) {
  return axiosInstance.delete(DELETE_BALLOON + `?balloonId=${id}`);
}

export function editBallon(balloonId, color, colorFamily, size, image) {
  const postData = { balloonId, color, colorFamily, size, imageName: image };
  return axiosInstance.put(EDIT_BALLOON, postData);
}
