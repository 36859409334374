/// auth service

export const LOGIN = 'admin/auth/signin';

export const CHANGE_PASSWORD = 'admin/auth/changePassword';

export const LOGOUT = 'admin/auth/logout';

export const DASHBOARD = 'admin/user/dashboard';

export const UPLOAD_FILE = 'admin/auth/uploadBalloonInfo';

export const FILTER_LIST = 'admin/auth/filtersList';

