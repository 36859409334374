import { connect, useDispatch } from "react-redux";
import React, { useEffect } from "react";
import { Card } from "react-bootstrap";
import { tableCallAction, uploadingFileAction } from "../../store/actions/AuthActions";
import { notifyError, notifyTopRight } from "../common/Toaster";
import { uploadFile } from "../../services/AuthService";

function Uploading(props) {
  const dispatch = useDispatch();

  function onSubmit(file) {
    uploadFile(file)
      .then((response) => {
        console.log(response, " success response");
        notifyTopRight("Uploaded Successfully.");
        if (response.data.statusCode === 200) {
          dispatch(uploadingFileAction(false));
          dispatch(tableCallAction(true));
          localStorage.removeItem("file");
        }
      })
      .catch((error) => {
        dispatch(uploadingFileAction(false));
        localStorage.removeItem("file");

        notifyError(error.response?.data?.message);
      });
  }

  function getPhoto() {
    const url = localStorage.getItem("file");
    fetch(url)
      .then((res) => res.blob())
      .then((blob) => {
        const file = new File([blob], "File name", { type: "image/png" });
        console.log(file, " from local ");

        onSubmit(file);
      });
  }

  useEffect(() => {
    if (props.uploadSheet) {
      getPhoto();
      // console.log(file," file aa gyi")
      // onSubmit(file);
    }
  }, [props.uploadSheet]);
  return (
    <Card className="card-position">
      <div className="d-flex align-items-center" style={{ gap: "0.5rem" }}>
        <div class="spinner-border" role="status">
          <span class="visually-hidden"></span>
        </div>
        <p className="">Uploading...</p>
      </div>
    </Card>
  );
}

const mapStateToProps = (state) => {
  return {
    auth: state.auth.auth,
    uploadSheet: state.auth.uploadSheet,
  };
};
export default connect(mapStateToProps)(Uploading);
