import React, { useEffect, useState } from "react";
import { Modal } from "react-bootstrap";
import Spinner from "../common/Spinner";
import { editVendors } from "../../services/Vendor/VendorService";
import { notifyError, notifyTopRight } from "../common/Toaster";
import PhoneInput from "react-phone-input-2";
import Autocomplete from "react-google-autocomplete";
export default function EditVendor({ show, vendorData, onHide, table }) {
  console.log(vendorData, "old data");

  const [loader, setLoader] = useState(false);
  const [countryCode, setCountryCode] = useState("");
  const [name, setName] = useState("");
  const [phoneNumber, setPhoneNumber] = useState("");
  const [website, setWebsite] = useState("");
  const [address, setAddress] = useState("");
  const [location, setLocation] = useState({
    lat: 0,
    long: 0,
  });
  console.log(location, "address");
  let errorsObj = {
    name: "",
    phoneNumber: "",
    website: "",
  };
  const [errors, setErrors] = useState(errorsObj);

  const onUpdated = async (e) => {
    e.preventDefault();

    let error = false;
    const errorObj = { ...errorsObj };
    if (name === "") {
      errorObj.name = "This Field is Required !";
      error = true;
    }

    if (phoneNumber === "") {
      errorObj.phoneNumber = "This Field is Required !";
      error = true;
    }
    if (website === "") {
      errorObj.website = "This Field is Required !";
      error = true;
    }
    setErrors(errorObj);
    if (error) {
      return;
    }
    // setLoader(true);

    try {
      const response = await editVendors(
        vendorData?._id,
        name,
        countryCode,
        phoneNumber,
        website
      );
      notifyTopRight("Updated Successfully.");
      table();
      onHide();
      //   setLoader(false);
      console.log(response);
    } catch (error) {
      console.log(error.response, "helooooooooo");
      setLoader(false);
      notifyError(error.response?.data?.message);
    }
  };
  function handleLocation(location) {
    console.log(location, "location in ");
    if (location && location.geometry && location.geometry.location) {
      const { lat, lng } = location.geometry.location;

      // Do something with lat and lng
      console.log("Latitude:", lat());
      console.log("Longitude:", lng());
      setLocation({ lat: lat(), long: lng() });
    }
  }
  useEffect(() => {
    setName(vendorData?.name);
    setCountryCode(vendorData?.countryCode);
    setPhoneNumber(vendorData?.phoneNumber);
    setWebsite(vendorData?.website);
    setAddress(vendorData?.address);
  }, []);
  return (
    <Modal className="modal fade" show={show} centered>
      <div className="">
        <form onSubmit={onUpdated}>
          <div className="modal-header">
            <h4 className="modal-title fs-20">Edit Vendor</h4>
            <button
              type="button"
              className="btn close"
              onClick={() => onHide()}
            >
              <span>×</span>
            </button>
          </div>
          <div className="modal-body">
            <i className="flaticon-cancel-12 close"></i>
            <div className="add-contact-box">
              <div className="add-contact-content">
                <div className="form-group mb-3">
                  <label className="text-black font-w500">Name</label>
                  <div className="contact-name">
                    <input
                      type="text"
                      className="form-control"
                      value={name}
                      onChange={(e) => setName(e.target.value)}
                    />

                    {errors.name && (
                      <div className="text-danger fs-12">{errors.name}</div>
                    )}
                  </div>
                </div>
                <div className="form-group">
                  <label className="text-black font-w500">Phone Number</label>
                  <div className="contact-name d-flex">
                    <PhoneInput
                      country={"us"}
                      enableSearch={true}
                      value={"+"+countryCode}
                      //   value="+1"
                      onChange={(phone) => setCountryCode(phone)}
                    />
                    <input
                      className="form-control"
                      value={phoneNumber}
                      onChange={(e) => setPhoneNumber(e.target.value)}
                      placeholder="Phone number"
                      onInput={(e) =>
                        (e.target.value = e.target.value.slice(0, 15))
                      }
                      type="number"
                    />
                  </div>
                  {errors.phoneNumber && (
                    <div className="text-danger fs-12">
                      {errors.phoneNumber}
                    </div>
                  )}
                </div>

                <div className="form-group">
                  <label className="text-black font-w500">Address</label>
                  <div className="contact-name">
                    <Autocomplete
                      className="form-control"
                      apiKey={"AIzaSyDlZKv0TlSdaJQBOjnGZdTC1Lk2-LPPxzA"}
                      onPlaceSelected={(place) => {
                        handleLocation(place);
                      }}
                      options={{
                        types: ["(regions)"],
                      }}
                      defaultValue={address}
                      placeholder="address"
                    />
                  </div>
                  {/* {errors.phoneNumber && (
                    <div className="text-danger fs-12">
                      {errors.phoneNumber}
                    </div>
                  )} */}
                </div>

                <div className="form-group mb-3">
                  <label className="text-black font-w500">Website</label>
                  <div className="contact-name">
                    <input
                      type="text"
                      className="form-control"
                      value={website}
                      onChange={(e) => setWebsite(e.target.value)}
                    />

                    {errors.website && (
                      <div className="text-danger fs-12">{errors.website}</div>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="modal-footer">
            <button type="submit" className="btn btn-info">
              Update
            </button>
            <button
              type="button"
              onClick={() => onHide()}
              className="btn btn-danger"
            >
              {" "}
              <i className="flaticon-delete-1"></i> Discard
            </button>
          </div>
        </form>
      </div>
      {loader && <Spinner />}
    </Modal>
  );
}
