import React from "react";
import Lottie from "lottie-react";
import animationData from "./Animation - 1701762481074.json";
export default function Spinner() {
  return (
    <div>
      <Lottie
        animationData={animationData}
        style={{ height: "200px", marginTop: "45px", padding: "30px" }}
      />
    </div>
  );
}
