import React, { useState } from "react";
import PageTitle from "../layouts/PageTitle";
import { connect, useDispatch } from "react-redux";
import { uploadingFileAction } from "../../store/actions/AuthActions";
import Spinner from "../common/Spinner";

function UploadSheet(props) {
  const dispatch = useDispatch();
  const [loader, setLoader] = useState(false);
  const [sheet, setSheet] = useState("");
  let errorsObj = { sheet: "" };
  const [errors, setErrors] = useState(errorsObj);
  function onClick() {
    let error = false;
    const errorObj = { ...errorsObj };
    if (sheet === "") {
      errorObj.sheet = "Please select a file";
      error = true;
    }
    setErrors(errorObj);
    if (error) {
      return;
    }
  }
  function test(value) {
    const excelFile = localStorage.getItem("file");
    if (excelFile) {
      dispatch(uploadingFileAction(value));
    }
  }
  function test1(file) {
    // dispatch(setFileAction(file));
    // localStorage.setItem("excelFile",file);
    var reader = new FileReader();
    reader.onload = function (base64) {
      localStorage["file"] = base64.currentTarget.result;
      // console.log(base64.currentTarget.result, " base 64");
    };
    reader.readAsDataURL(file);
    // console.log(file, " filee....");
  }

  return (
    <div>
      <PageTitle activeMenu="Upload Sheet" motherMenu="Upload Sheet" />
      <div className="content-background">
        <form>
          <div className="form-group">
            <label className="mb-2 text-black">
              <strong className="">Select File</strong>
            </label>
            <input
              type="file"
              className="form-control"
              accept=".csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel, .ods,"
              onChange={(e) => {
                test1(e.target.files[0]);
                setSheet(e.target.files[0]);
              }}
            />
            {errors.sheet && (
              <div className="text-danger fs-12">{errors.sheet}</div>
            )}
          </div>
          <div className="text-center mt-4">
            <button
              type="button"
              className={
                "btn btn-primary btn-block " +
                (props.uploadSheet === true ? "disabled" : "")
              }
              onClick={(e) => {
                test(true);
                onClick();
              }}
            >
              Submit
            </button>
          </div>
        </form>
      </div>
      {loader && <Spinner />}
    </div>
  );
}
const mapStateToProps = (state) => {
  return {
    auth: state.auth.auth,
    uploadSheet: state.auth.uploadSheet,
  };
};
export default connect(mapStateToProps)(UploadSheet);
