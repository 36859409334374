import React, { useEffect, useState } from "react";
import { Modal } from "react-bootstrap";
import { notifyError, notifyTopRight } from "../common/Toaster";
import { editFaq,} from "../../services/FAQs/FaqsService";


const EditFaq = ({ show, table, data, onHide }) => {
  let errorsObj = {
    question: "",
    answer: "",
  };
  const [errors, setErrors] = useState(errorsObj);
  const [formData, setFormData] = useState({
    question: "",
    answer: "",
   
    faqId: data?._id,
  });

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
    setErrors({
      ...errors,
      [name]: "", // Clear validation error when the user starts typing
    });
  };
  //// function to add data/////
  const handleSubmit = async (e) => {
    e.preventDefault();
    let error = false;
    const errorObj = { ...errorsObj };
    if (formData.question.trim() === "") {
      errorObj.question = "This field is required";
      error = true;
    }
    if (formData.answer.trim() === "") {
      errorObj.answer = "This field is required";
      error = true;
    }

    setErrors(errorObj);
    if (error) {
      return;
    }
    try {
      const response = await editFaq(formData);
      console.log(response.data.message, "success");
      setFormData({
        question: "",
        answer: "",
        faqId: "",
      });

      notifyTopRight(response?.data?.message);
      table();
      onHide();
    } catch (error) {
      console.log(error, "error");
      notifyError(error?.response?.data?.message);
    }
  };

  useEffect(() => {
    ////here we set previous data///
    if (show && data) {
      setFormData({
        ...formData,
        question: data?.question,
        answer: data?.answer,
      });
    }
  }, [show]);
  return (
    <Modal className="modal fade" show={show} onHide={onHide} centered>
      <div className="" role="document">
        <form onSubmit={handleSubmit}>
          <div className="modal-header">
            <h4 className="modal-title fs-20">Edit FAQs</h4>
            {/* <button type="button" className="btn-close"  data-dismiss="modal"><span></span></button> */}
          </div>
          <div className="modal-body">
            <i className="flaticon-cancel-12 close" data-dismiss="modal"></i>
            <div className="add-contact-box">
              <div className="add-contact-content">
                <div className="form-group mb-3">
                  <label className="text-black font-w500">Question</label>
                  <div className="contact-name">
                    <input
                      type="text"
                      className="form-control"
                      placeholder="Enter question"
                      name="question"
                      value={formData.question}
                      onChange={handleInputChange}
                    />
                    <span className="validation-text text-danger">
                      {errors.question}
                    </span>
                  </div>
                </div>
                <div className="form-group mb-3">
                  <label className="text-black font-w500">Answer</label>
                  <div className="contact-name">
                    <input
                      type="text"
                      className="form-control"
                      placeholder="Enter answer"
                      name="answer"
                      value={formData.answer}
                      onChange={handleInputChange}
                    />
                    <span className="validation-text text-danger">
                      {errors.answer}
                    </span>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="modal-footer">
            <button type="submit" className="btn btn-primary">
              Update
            </button>
            <button
              type="button"
              onClick={() => onHide()}
              className="btn btn-danger"
            >
              Discard
            </button>
          </div>
        </form>
      </div>
      {/* {loader && <Spinner />} */}
    </Modal>
  );
};
export default EditFaq;
