import React, { useEffect, useState } from "react";
import { Dropdown, Card, Table, Badge, Col } from "react-bootstrap";
import PageTitle from "../layouts/PageTitle";
import Spinner from "../common/Spinner";
import Pagination from "../common/Pagination";
import { blockUser, getAllUsers } from "../../services/User/UserService";
import { notifyError, notifyTopRight } from "../common/Toaster";
import emptyImg from "../../images/Profile_avatar_placeholder_large.png";
export default function UserManagement(props) {
  const [loader, setLoader] = useState(false);
  const [users, setUsers] = useState([]);
  const [pageCount, setPageCount] = useState(0);
  const [currentPage, setCurrentPage] = useState(0);
  const [search, setSearch] = useState("");
  const [sort, setSort] = useState("");
  const [filter, setFilter] = useState("");
  const [limit, setLimit] = useState(10);
  const [listLength, setListlenght] = useState("");

  const svg1 = (
    <svg width="20px" height="20px" viewBox="0 0 24 24" version="1.1">
      <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
        <rect x="0" y="0" width="24" height="24"></rect>
        <circle fill="#000000" cx="5" cy="12" r="2"></circle>
        <circle fill="#000000" cx="12" cy="12" r="2"></circle>
        <circle fill="#000000" cx="19" cy="12" r="2"></circle>
      </g>
    </svg>
  );

  function getTableData() {
    setLoader(true);
    getAllUsers(currentPage, limit, search, sort, filter)
      .then((response) => {
        console.log(response.data.data);
        setUsers(response.data.data.users);
        setLoader(false);
        const total = response.data?.data?.userCount;
        setPageCount(Math.ceil(total / limit));
        setListlenght(response.data?.data?.userCount);
      })
      .catch((error) => {
        console.log(error, "error");
        setLoader(false);
      });
  }

  function onAction(id, isBlocked) {
    setLoader(true);
    blockUser(id, isBlocked)
      .then((response) => {
        {
          isBlocked === false
            ? notifyTopRight("Unblocked Successfully.")
            : notifyTopRight("Blocked Successfully.");
        }

        getTableData();
        setLoader(false);

        console.log(response, "block response");
      })
      .catch((error) => {
        console.log(error.response, "helooooooooo");
        setLoader(false);
        notifyError(error?.response?.data?.data);
      });
  }

  useEffect(() => {
    getTableData();
  }, [currentPage, search, limit,sort,filter]);

  return (
    <div>
      <PageTitle activeMenu="Customer List" motherMenu="Customer" />
      <Col>
        <Card>
          <Card.Header className="d-block">
            <div className="d-flex justify-content-between align-items-center">
              <div className="col-4" style={{ flexGrow: 1 }}>
                <div style={{ display: "flex", alignItems: "center" }}>
                  <div
                    className="input-group border bg-white input-group-sm"
                    style={{ borderRadius: "8px" }}
                  >
                    <input
                      type="text"
                      name="table_search"
                      className="form-control float-right border-0 search-input"
                      placeholder="Search"
                      onKeyDown={(e) => {
                        console.log(e.key);
                        if (e.key === "Enter") {
                          setCurrentPage(0);
                          getTableData();
                        }
                      }}
                      onChange={(e) => setSearch(e.target.value)}
                    />
                    <div className="input-group-append">
                      <button
                        type="button"
                        className="btn btn-default"
                        onClick={getTableData}
                      >
                        <i className="fa fa-search" />
                      </button>
                    </div>
                  </div>
                </div>
              </div>
              <div
                className="col-6 d-flex justify-content-between align-items-center"
                style={{ gap: "1rem" }}
              >
                <select
                  className="form-control"
                  onChange={(e) => setSort(e.target.value)}
                >
                  <option value={""}>Sort By</option>
                  <option value={"alphabetical"}>Alphabetically</option>
                  <option value={"latest"}>Latest</option>
                </select>
                <select
                  className="form-control"
                  onChange={(e) => setFilter(e.target.value)}
                >
                  <option value={""}>Filter By</option>
                  <option value={false}>Enable</option>
                  <option value={true}>Disable</option>
                </select>
              </div>
            </div>
          </Card.Header>
          <Card.Body>
            {loader ? (
              <Spinner />
            ) : (
              <Table responsive>
                <thead className="text-black">
                  <tr>
                    <th>
                      <strong>IMAGE</strong>
                    </th>
                    <th>
                      <strong>First NAME</strong>
                    </th>
                    <th>
                      <strong>last NAME</strong>
                    </th>
                    <th>
                      <strong>EMAIL</strong>
                    </th>
                    <th>
                      <strong>PHONE NUMBER</strong>
                    </th>

                    <th>
                      <strong>STATUS</strong>
                    </th>
                    <th>
                      <strong>ACTION</strong>
                    </th>
                  </tr>
                </thead>
                <tbody>
                  {users?.map((item, i) => (
                    <tr key={i}>
                      <td>
                        <img
                          src={
                            item?.profilePicture
                              ? item?.profilePicture
                              : emptyImg
                          }
                          width={70}
                          height={70}
                        />
                      </td>
                      <td>{item?.name ? item?.name : "--"}</td>
                      <td>{item?.lastname ? item?.lastname : "--"}</td>
                      <td>{item?.email}</td>
                      <td>
                        {item?.countryCode ? "+" + item?.countryCode : ""}{" "}
                        {item?.phoneNumber ? item?.phoneNumber : "--"}
                      </td>
                      <td className="text-center">
                        {item.isBlocked ? (
                          <Badge variant="danger light">Disable</Badge>
                        ) : (
                          <Badge variant="success light">Enable</Badge>
                        )}
                      </td>
                      <td>
                        <Dropdown>
                          <Dropdown.Toggle
                            variant="primary light"
                            className="light sharp btn btn-primary i-false"
                          >
                            {svg1}
                          </Dropdown.Toggle>
                          <Dropdown.Menu>
                            {item.isBlocked ? (
                              <Dropdown.Item
                                onClick={() => onAction(item._id, false)}
                              >
                                Enable
                              </Dropdown.Item>
                            ) : (
                              <Dropdown.Item
                                onClick={() => onAction(item._id, true)}
                              >
                                Disable
                              </Dropdown.Item>
                            )}

                            {/* <Dropdown.Item onClick={() => onDelete(item._id)}>
                              Delete
                            </Dropdown.Item> */}
                          </Dropdown.Menu>
                        </Dropdown>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </Table>
            )}

            {users?.length === 0 && !loader ? (
              <div className="justify-content-center d-flex my-5 ">
                Sorry, Data Not Found!
              </div>
            ) : (
              ""
            )}
            {users?.length === 0 ? (
              ""
            ) : (
              <div className="card-footer clearfix">
                <div className="d-flex justify-content-between align-items-center">
                  <div className="dataTables_info ">
                    Showing {currentPage * limit + 1} to{" "}
                    {listLength > (currentPage + 1) * limit
                      ? (currentPage + 1) * limit
                      : listLength}{" "}
                    of {listLength} entries
                  </div>
                  <Pagination
                    pageCount={pageCount}
                    pageValue={currentPage}
                    setPage={setCurrentPage}
                  />
                </div>
                <div>
                  <label className="mr-2">Result Per page : </label>
                  <select
                    className=""
                    value={limit}
                    onChange={(e) => setLimit(e.target.value)}
                  >
                    <option>10</option>
                    <option>20</option>
                    <option>30</option>
                    <option>50</option>
                    <option>100</option>
                  </select>
                </div>
              </div>
            )}
          </Card.Body>
        </Card>
      </Col>
    </div>
  );
}
