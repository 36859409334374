import React, { useEffect, useState } from "react";
import { Dropdown, Card, Table, Badge } from "react-bootstrap";
import PageTitle from "../layouts/PageTitle";
import Spinner from "../common/Spinner";
import Pagination from "../common/Pagination";
import dummy from "../../images/Profile_avatar_placeholder_large.png";
import { notifyError, notifyTopRight } from "../common/Toaster";
import { useHistory } from "react-router-dom";
import { deleteVendor, getVendors } from "../../services/Vendor/VendorService";
import EditVendor from "../modal/EditVendor";
import UploadFile from "../modal/UploadFile";
import { connect } from "react-redux";
import { getFiltersData } from "../../services/AuthService";
function VendorManagement(props) {
  const isTableCall = props.tableCall;
  console.log(isTableCall, "local value");
  const history = useHistory();
  const newpage = props.location.state?.page;
  const prevSearch = props.location.state?.search;
  const [loader, setLoader] = useState(false);
  const [vendors, setVendors] = useState([]);
  const [pageCount, setPageCount] = useState(0);
  const [currentPage, setCurrentPage] = useState(newpage ? newpage : 0);
  const [search, setSearch] = useState(prevSearch ? prevSearch : "");
  const [listLength, setListlenght] = useState("");
  const [showEditModal, setShowEditModal] = useState(false);
  const [showAddModal, setShowAddModal] = useState(false);
  const [vendorData, setVendorData] = useState({});
  const [limit, setLimit] = useState(10);
  const [sort, setSort] = useState("");
  const [filter, setFilter] = useState("");
  const [location, setLocation] = useState([]);
  const svg1 = (
    <svg width="20px" height="20px" viewBox="0 0 24 24" version="1.1">
      <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
        <rect x="0" y="0" width="24" height="24"></rect>
        <circle fill="#000000" cx="5" cy="12" r="2"></circle>
        <circle fill="#000000" cx="12" cy="12" r="2"></circle>
        <circle fill="#000000" cx="19" cy="12" r="2"></circle>
      </g>
    </svg>
  );

  ///////////////// function to get all users /////////////////////////////////
  const getTableData = async () => {
    setLoader(true);
    try {
      const response = await getVendors(
        currentPage,
        limit,
        search,
        sort,
        filter
      );
      console.log(response.data.data);
      setVendors(response.data.data.vendors);
      setLoader(false);
      localStorage.removeItem("table");
      const total = response.data.data.vendorCount;
      setPageCount(Math.ceil(total / limit));
      setListlenght(response.data.data.vendorCount);
    } catch (error) {
      console.log(error, "error");
      localStorage.removeItem("table");

      setLoader(false);
    }
  };
  const FetchFilterData = async () => {
    setLoader(true);
    try {
      const response = await getFiltersData();
      setLocation(response.data.data?.locations);
      console.log(response.data.data, "filter data");
    } catch (error) {
      console.log(error, "error");
      setLoader(false);
    }
  };

  ///////////////// function to block/ unlock user /////////////////////////////////
  const onDelete = async (id) => {
    setLoader(true);
    try {
      const response = await deleteVendor(id);
      notifyTopRight("Deleted Successfully.");
      getTableData();
      setLoader(false);
      console.log(response);
    } catch (error) {
      console.log(error.response, "helooooooooo");
      setLoader(false);
      notifyError(error.response?.data?.message);
    }
  };
  const onEdit = (data) => {
    setVendorData(data);
    setShowEditModal(true);
  };

  useEffect(() => {
    getTableData();
  }, [currentPage, limit, sort, filter]);
  useEffect(() => {
    FetchFilterData();
  }, []);
  useEffect(() => {
    if (isTableCall === true) {
      getTableData();
    }
  }, [isTableCall]);

  useEffect(() => {
    if (currentPage) {
      history.push({
        pathname: "/vendor-management",
        state: {
          page: currentPage,
        },
      });
    }
  }, [currentPage]);

  useEffect(() => {
    if (search) {
      history.push({
        pathname: "/vendor-management",
        state: {
          page: currentPage,
          search: search,
        },
      });
    }
  }, [limit, search]);
  return (
    <div>
      <PageTitle activeMenu="Vendor List" motherMenu="Vendors" />
      <Card>
        <Card.Header>
          <div className="col-4">
            <div
              className="input-group border bg-white input-group-sm rounded-border"
              style={{ borderRadius: "8px" }}
            >
              <input
                type="text"
                name="table_search"
                className="form-control float-right border-0 search-input"
                placeholder="Search"
                value={search}
                onKeyDown={(e) => {
                  if (e.key === "Enter") {
                    setCurrentPage(0);
                    getTableData();
                  }
                }}
                onChange={(e) => setSearch(e.target.value)}
              />
              <div className="input-group-append">
                <button
                  type="button"
                  className="btn btn-default"
                  onClick={getTableData}
                >
                  <i className="fa fa-search" />
                </button>
              </div>
            </div>
          </div>
          <div
            className="col-6 d-flex justify-content-between align-items-center"
            style={{ gap: "1rem" }}
          >
            <select
              className="form-control"
              onChange={(e) => setSort(e.target.value)}
            >
              <option value={""}>Sort By</option>
              <option value={"alphabetical"}>Alphabetically</option>
              <option value={"latest"}>Latest</option>
            </select>
            <select
              className="form-control"
              onChange={(e) => setFilter(e.target.value)}
            >
              <option value={""}>Filter By</option>
              {location?.map((item, i) => (
                <option>{item}</option>
              ))}
            </select>
          </div>

          <button
            type="button"
            className="btn btn-primary py-2"
            onClick={() => setShowAddModal(true)}
          >
            Add +
          </button>
        </Card.Header>
        <Card.Body>
          {loader ? (
            <Spinner />
          ) : (
            <Table responsive>
              <thead className="text-black">
                <tr>
                  <th>
                    <strong>IMAGE</strong>
                  </th>

                  <th>
                    <strong>NAME</strong>
                  </th>
                  <th>
                    <strong>email</strong>
                  </th>
                  <th>
                    <strong>PHONE NUMBER</strong>
                  </th>
                  <th>
                    <strong>address</strong>
                  </th>
                  <th>
                    <strong>ACTION</strong>
                  </th>
                </tr>
              </thead>
              <tbody>
                {vendors?.map((item, i) => (
                  <tr key={i}>
                    <td>
                      <img src={dummy} width={70} height={70} />
                    </td>

                    <td>{item?.name ? item?.name : "--"}</td>
                    <td>{item?.email ? item?.email : "--"}</td>
                    <td>
                      {item?.countryCode ? "+" + item?.countryCode : ""}
                      {item?.phoneNumber ? " " + item?.phoneNumber : "--"}
                    </td>
                    <td>
                      {item?.address}, {item?.country}
                    </td>
                    <td>
                      <Dropdown>
                        <Dropdown.Toggle
                          variant="primary light"
                          className="light sharp btn btn-primary i-false"
                        >
                          {svg1}
                        </Dropdown.Toggle>
                        <Dropdown.Menu>
                          <Dropdown.Item onClick={() => onEdit(item)}>
                            Edit
                          </Dropdown.Item>

                          <Dropdown.Item onClick={() => onDelete(item._id)}>
                            Delete
                          </Dropdown.Item>
                        </Dropdown.Menu>
                      </Dropdown>
                    </td>
                  </tr>
                ))}
              </tbody>
            </Table>
          )}
          {vendors?.length === 0 && !loader && (
            <div className="justify-content-center d-flex my-5 ">
              Sorry, Data Not Found!
            </div>
          )}

          {vendors?.length !== 0 && (
            <div className="card-footer clearfix">
              <div className="d-flex justify-content-between align-items-center">
                <div className="dataTables_info">
                  Showing {currentPage * limit + 1} to{" "}
                  {listLength > (currentPage + 1) * limit
                    ? (currentPage + 1) * limit
                    : listLength}{" "}
                  of {listLength} entries
                </div>
                <Pagination
                  pageCount={pageCount}
                  pageValue={currentPage}
                  setPage={setCurrentPage}
                />
              </div>
              <div>
                <label className="mr-2">Result Per page : </label>
                <select
                  className=""
                  value={limit}
                  onChange={(e) => setLimit(e.target.value)}
                >
                  <option>10</option>
                  <option>20</option>
                  <option>30</option>
                  <option>50</option>
                  <option>100</option>
                </select>
              </div>
            </div>
          )}
        </Card.Body>
      </Card>
      {showEditModal && (
        <EditVendor
          show={showEditModal}
          vendorData={vendorData}
          table={getTableData}
          onHide={() => setShowEditModal(false)}
        />
      )}
      {showAddModal && (
        <UploadFile show={showAddModal} onHide={() => setShowAddModal(false)} />
      )}
    </div>
  );
}
const mapStateToProps = (state) => {
  return {
    auth: state.auth.auth,
    tableCall: state.auth.tableCall,
  };
};
export default connect(mapStateToProps)(VendorManagement);
