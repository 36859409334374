import axios from "axios";
import { loginConfirmedAction, logout } from "../store/actions/AuthActions";
import {
  CHANGE_PASSWORD,
  DASHBOARD,
  FILTER_LIST,
  LOGIN,
  LOGOUT,
  UPLOAD_FILE,
} from "./AuthApiEndPoints";
import axiosInstance from "./AxiosInstance";
import { notifyError } from "../jsx/common/Toaster";
import AWS from "aws-sdk";
export function signUp(email, password) {
  //axios call

  const postData = {
    email,
    password,
    returnSecureToken: true,
  };

  return axios.post(
    `https://identitytoolkit.googleapis.com/v1/accounts:signUp?key=AIzaSyD3RPAp3nuETDn9OQimqn_YF6zdzqWITII`,
    postData
  );
}

export function login(email, password) {
  const postData = {
    email,
    password,
  };

  return axiosInstance.post(LOGIN, postData);
}

export function dashboard(filter) {
  return axiosInstance.get(DASHBOARD + `?dateFilter=${filter}`);
}
export function getFiltersData() {
  return axiosInstance.get(FILTER_LIST);
}
export function changePasswoard(oldPassword, newPassword) {
  const postData = {
    oldPassword,
    newPassword,
  };

  return axiosInstance.put(CHANGE_PASSWORD, postData);
}
export function logOut() {
  return axiosInstance.post(LOGOUT, { test: "" });
}
export function uploadFile(file) {
  console.log(file, " data........");
  const token = localStorage.getItem("tokenDetails");
  const form = new FormData();
  form.append("file", file);
  console.log(form, "after append");
  return axios({
    // timeout: 1000*6,
    method: "post",
    url: `https://api.ballooncolorpro.com/admin/auth/uploadBalloonInfo`,
    data: form,
    headers: {
      "Content-Type": "multipart/form-data",
      Authorization: `Bearer ${token}`,
    },
  });
}

export function formatError(errorResponse) {
  notifyError(errorResponse);
}

export function saveTokenInLocalStorage(tokenDetails) {
  localStorage.setItem("tokenDetails", tokenDetails);
}

export function runLogoutTimer(dispatch, timer, history) {
  setTimeout(() => {
    dispatch(logout(history));
  }, timer);
}

export function checkAutoLogin(dispatch, history) {
  const tokenDetailsString = localStorage.getItem("tokenDetails");
  // let tokenDetails = "";
  if (!tokenDetailsString) {
    history.push("/");
    dispatch(logout(history));
    return;
  }
  dispatch(loginConfirmedAction(tokenDetailsString));
}

const NEXT_PUBLIC_COGNITO_POOL_ID = process.env.REACT_APP_BUCKET_POOL_ID;
const NEXT_PUBLIC_COGNITO_POOL_REGION = process.env.REACT_APP_BUCKET_REGION;
const NEXT_PUBLIC_S3_BUCKET_NAME = process.env.REACT_APP_BUCKET_NAME;

AWS.config.update({
  region: NEXT_PUBLIC_COGNITO_POOL_REGION,
  credentials: new AWS.CognitoIdentityCredentials({
    IdentityPoolId: NEXT_PUBLIC_COGNITO_POOL_ID,
  }),
});

export default async function addPhoto(image, albumName) {
  console.log(image, albumName, "file in test files");
  var file = image;
  var fileName = Math.floor(Math.random() * 10000000) + file.name;
  var albumPhotosKey = encodeURIComponent(albumName) + "/";
  var photoKey = albumPhotosKey + fileName;

  var upload = new AWS.S3.ManagedUpload({
    params: {
      Bucket: NEXT_PUBLIC_S3_BUCKET_NAME,
      Key: photoKey,
      Body: file,
      ACL: "public-read",
    },
  });

  var promise = await upload.promise();
  promise.imageName = fileName;
  console.log(promise.imageName, "promise 1111111111111111");
  return promise;
}
