import axiosInstance from "../AxiosInstance";
import {
  ADD_VENDORS,
  DELETE_VENDORS,
  EDIT_VENDORS,
  GET_VENDORS,
} from "./VendorApiEndPoints";
export function getVendors(currentPage, limit, search, sort, filter) {
  return axiosInstance.get(
    GET_VENDORS +
      `?page=${currentPage}&limit=${limit}&search=${search}&sort=${sort}&filter=${filter}`
  );
}

export function deleteVendor(id) {
  return axiosInstance.delete(DELETE_VENDORS + `?vendorId=${id}`);
}

export function createVendor(id) {
  const postData = { id };
  return axiosInstance.post(ADD_VENDORS, postData);
}

export function editVendors(vendorId, name, countryCode, phoneNumber, website) {
  const postData = { vendorId, name, countryCode, phoneNumber, website };
  return axiosInstance.put(EDIT_VENDORS, postData);
}
