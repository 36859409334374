import React, { useEffect, useState } from "react";
import { Accordion, Card, Col } from "react-bootstrap";
import PageTitle from "../layouts/PageTitle";
import Spinner from "../common/Spinner";
import AddFaq from "../modal/AddFaq";
import deleteSvg from "../../images/Property 1=Default.svg";
import hoverDelete from "../../images/Property 1=Variant2 (2).svg";
import { notifyError, notifyTopRight } from "../common/Toaster";
import EditFaq from "../modal/EditFaq";
import Pagination from "../common/Pagination";
import { deleteFaq, getFaqsList } from "../../services/FAQs/FaqsService";
export default function FAQS() {
  const [isDeleteHovered, setDeleteHovered] = useState("");
  const [active, setActive] = useState(0);
  const [data, setData] = useState([]);
  const [loader, setLoader] = useState(false);
  const limit = 10;
  const [listLength, setListlenght] = useState(0);
  const [pageCount, setPageCount] = useState(0);
  const [currentPage, setCurrentPage] = useState(0);
  const [showAddModal, setShowAddModal] = useState(false);
  const [showEditModal, setShowEditModal] = useState(false);
  const [oneData, setOneData] = useState(null);

  ///function to fetch table data ///
  const handleFetch = async () => {
    setLoader(true);
    try {
      const response = await getFaqsList(currentPage, limit);
      console.log(response, "success");
      setData(response?.data?.data.faq);
      setLoader(false);
      const total = response.data?.data?.faqCount;
      setPageCount(Math.ceil(total / limit));
      setListlenght(response.data?.data?.faqCount);
    } catch (error) {
      setLoader(false);
    }
  };

  ///function to delete data ///
  const handleDelete = async (id) => {
    setLoader(true);
    try {
      const response = await deleteFaq(id);
      handleFetch();
      notifyTopRight("Deleted Successfully!");
      setLoader(false);
    } catch (error) {
      notifyError(error?.response?.data?.message);
      console.log(error, "error");
      setLoader(false);
    }
  };
  ///function to edit data ///
  const handleEdit = async (data) => {
    setOneData(data);
    setShowEditModal(true);
  };

  useEffect(() => {
    handleFetch();
  }, [currentPage]);

  return (
    <div>
      <PageTitle activeMenu="FAQs" motherMenu="FAQs" />
      <Col>
        <Card>
          <Card.Header className="d-flex">
            <Card.Title>FAQs List</Card.Title>
            <button
              className="btn btn-primary py-2"
              onClick={() => setShowAddModal(true)}
            >
              Add +
            </button>
          </Card.Header>
          <Card.Body>
            {loader ? (
              <Spinner />
            ) : (
              <Accordion
                className="accordion accordion-no-gutter "
                //   defaultActiveKey="0"
              >
                {data?.map((d, i) => (
                  <div className="accordion__item" key={i}>
                    <Accordion.Toggle
                      as={Card.Text}
                      eventKey={`${i}`}
                      className={`accordion__header ${
                        active === i ? "" : "collapsed"
                      }`}
                      onClick={() => setActive(active === i ? -1 : i)}
                    >
                      <span className="accordion__header--text">{d?.question}</span>
                      <span className="accordion__header--indicator style_two"></span>
                      {/* <span className={`accordion__header--indicator style-two `}></span> */}
                      <span className="accordion__header--icons">
                        <button
                          className="btn btn-info light mr-2"
                          style={{ padding: "7px 10px" }}
                          onClick={(e) => {
                            e.stopPropagation(); // Prevent accordion toggle
                            handleEdit(d);
                          }}
                        >
                          <i className="flaticon-381-edit-1"></i>
                        </button>
                        <img
                          src={isDeleteHovered === i ? hoverDelete : deleteSvg}
                          alt="img"
                          width={40}
                          className="mr-1 "
                          onClick={(e) => {
                            e.stopPropagation(); // Prevent accordion toggle
                            handleDelete(d?._id);
                          }}
                          onMouseEnter={() => setDeleteHovered(i)}
                          onMouseLeave={() => setDeleteHovered("")}
                        />
                      </span>
                    </Accordion.Toggle>
                    <Accordion.Collapse eventKey={`${i}`}>
                      <div className="accordion__body--text">{d?.answer}</div>
                    </Accordion.Collapse>
                  </div>
                ))}
                {data?.length === 0 && !loader ? (
                  <div className="justify-content-center d-flex my-5 ">
                    No data found!
                  </div>
                ) : (
                  ""
                )}
              </Accordion>
            )}
            {data?.length !== 0 && (
              <div className="card-footer clearfix">
                <div className="d-block d-sm-flex justify-content-between align-items-center">
                  <div className="dataTables_info">
                    Showing {currentPage * limit + 1} to{" "}
                    {listLength > (currentPage + 1) * limit
                      ? (currentPage + 1) * limit
                      : listLength}{" "}
                    of {listLength} entries
                  </div>
                  <Pagination
                    pageCount={pageCount}
                    pageValue={currentPage}
                    setPage={setCurrentPage}
                  />
                </div>
              </div>
            )}
          </Card.Body>
        </Card>
      </Col>
      {showAddModal && (
        <AddFaq
          show={showAddModal}
          table={handleFetch}
          onHide={() => setShowAddModal(false)}
        />
      )}

      {showEditModal && (
        <EditFaq
          show={showEditModal}
          table={handleFetch}
          data={oneData}
          onHide={() => setShowEditModal(false)}
        />
      )}
    </div>
  );
}
